import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { NextPage } from "next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from '@emotion/styled';
import Image from 'next/image';
import { modals } from "./modal/Modals";
import useModals from "./modal/useModals";
import PageStore from "stores/PageStore";
import { observer } from "mobx-react-lite";
import Const from "Const";
import ChannelService from "ChannelService";
import Party from "types/Party";
import dynamic from "next/dynamic";
import EventType from "types/EventType";
import PageMenu from "types/PageMenu";
import throttle from "lodash.throttle";
import { SwiperSlide, Swiper } from "swiper/react";
import { Sidebar } from 'primereact/sidebar';
import Theme from "types/Theme";
import Util from "Util";
import { toast } from "react-toastify";

const 세번째페이지 = dynamic(() => import('./세번째페이지'));

interface Props {
  currentPageMenu: PageMenu;
  onClose?: () => void;
}

const Container = styled.div<{currentPageMenu: PageMenu}>`
  left: ${props => props.currentPageMenu === PageMenu.홈 ? '0' : '-200vw'};
  right: 0;
  top: 0;
  padding-top: 62px;
  padding-bottom: 108px;
  bottom: 0;
  margin: auto;
  position: fixed;
  width: 100%;
  max-width: 444px;
  height: 100%;
  z-index: 3640;
  background-color: white;
  transition: all .46s cubic-bezier(0, 0, 0.2, 1);
  overflow-y: auto;
  overflow-x: hidden;

  &, & * {
    touch-action: auto;
  }
`;

const MainSwiper = styled(Swiper)`
`;

const 두번째페이지: NextPage<Props> = ({currentPageMenu, onClose}) => {
  const { openModal } = useModals();
  const [activeNotice, setActiveNotice] = useState<any>(null);

  const on대충스크롤바Scroll = useMemo(() => throttle(e => {
    if (e.target.scrollLeft > 0) {
      e.target.classList.add('left');
    } else {
      e.target.classList.remove('left');
    }
  }, 50), []);

  const openShareModal = useCallback(() => {
    openModal(modals.shareModal, {});
  }, []);

  const openQRModal = useCallback(() => {
    openModal(modals.QRModal, {})
  }, []);

  const open이미지Modal = useCallback((url, isAllowDownload, fileName) => {
    openModal(modals.이미지Modal, {url, isAllowDownload, fileName})
  }, []);

  const doCall = useCallback((phone: string) => {
    if (!phone) return;

    Util.phoneCall(phone);
  }, []);

  const doShare = useCallback(() => {
    openShareModal();
  }, []);

  const doQrcode = useCallback(() => {
    openQRModal();
  }, []);

  const doSMS = useCallback((phone: string, msg: string = '') => {
    if (!phone) return;

    Util.sendMessage(phone, msg);
  }, []);

  const doCopyLink = useCallback((link: string) => {
    if (!link) return;

    toast.info('클립보드에 복사되었습니다');

    Util.copyText(link);
  }, []);

  const doOpenLink = useCallback((link: string) => {
    if (!link) return;

    window.open(link, '_blank');
  }, []);

  const installMobileHome = useCallback(() => {
    openModal(modals.디탄발Modal, {});
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (Number(params.get('tbp')) === 1) {
      installMobileHome();

      params.delete('tbp');

      window.history.replaceState(null, window.document.title, `${window.location.href.replace(window.location.search, '')}${params.toString()}`);
    }
  }, []);

  return (
    <>
    {PageStore.page && (
      <>
      <Box>
        
        <세번째페이지 currentPageMenu={currentPageMenu} />
      </Box>

      <Container currentPageMenu={currentPageMenu} >
        <>
        {/* {currentPageMenu === PageMenu.홈 && (
          <>

          <button className={`absolute left-0 right-0 bottom-[calc(16px+env(safe-area-inset-bottom))] z-30 w-[90%] h-[50px] ${PageStore.page.party === Party.더불어민주당 ? 'bg-민주당 border-mint2' : 'bg-[#00b5e2] border-[#d7fa28]'} m-auto rounded-full border shadow-md`}onClick={() => ChannelService!.showMessenger()}>
            <Typography variant="subtitle1" className="text-white font-black"><span className="text-black">{PageStore.page.personName}</span> 후보에게 응원, 정책제안을 해 주세요
            </Typography>
            
          </button>
          </>
        )} */}
    
        </>

        {/* <div className="absolute right-[50px] bottom-[calc(env(safe-area-inset-bottom)+19px)] z-30 leading-[0]" onClick={() => ChannelService!.showMessenger()}>
          <Image src={Const[`${PageStore.page.party}MIC_IMAGE`]} width="200px" height="140px" objectFit="contain" />

        </div> */}

        {/* <div className="absolute left-0 right-0 text-center mx-auto bottom-[calc(8px+env(safe-area-inset-bottom))] z-10 ">
          <Image src={Const[`${PageStore.page.party}_IMAGE`]} width="80px" height="40px" objectFit="contain" />
        </div> */}


        <div className="p-[20px] relative w-full h-[170px]">
          
          <div className="relative z-30">

            <Typography variant="h4" className="font-bold text-white ">{PageStore.page.personName}</Typography>
            <Typography variant="h6" className="leading-none text-white mt-2">{PageStore.page.race}</Typography>

            <Typography variant="caption" className="block mt-8 text-white w-[200px] whitespace-pre-wrap">{PageStore.page.comment}</Typography>
          </div>

          <div className={`absolute top-0 bottom-0 my-auto z-10 leading-[0] box-content overflow-hidden ${PageStore.page.profileImageTransparent ? 'right-0 aspect-[3/4] h-full' : 'right-[25px] border border-[#0abab5] rounded-full w-[120px] h-[120px]'}`}>
            <Image src={PageStore.page.profileImageURL} layout="responsive" width={PageStore.page.profileImageTransparent ? '3%' : '1%'} height={PageStore.page.profileImageTransparent ? '4%' : '1%'} objectFit="contain" />
          </div>
          
          <div className={`absolute left-0 top-0 h-full w-[85%] ${{[Theme.MINT]: 'bg-[#0ABAB5]', [Theme.SKY]: 'bg-[#00B5E2]', [Theme.BLUE]: 'bg-[#003668]', [Theme.PINK]: 'bg-[#EF476F]'}[PageStore.page.theme]}`} />
          
          <div className={`absolute right-0 top-0 h-full w-[calc(15%+32px)] ${{[Theme.MINT]: 'bg-[#81D8CF]', [Theme.SKY]: 'bg-[#E61E2B  ]', [Theme.BLUE]: 'bg-[#0081CC]', [Theme.PINK]: 'bg-[#F8EFD4]'}[PageStore.page.theme]} skew-x-[-10deg] translate-x-[15px]`} />
        </div>

        <Typography variant="h6" className="mt-5 mx-[20px] font-black italic text-[#333]">DIRECT</Typography>

        <div className="text-center flex leading-[0] relative">
          <div className="flex rounded-full ml-6 items-center shadow-md h-[70px] flex-[0_0_70px] relative" onClick={() => ChannelService!.showMessenger()}>
            <Image src={Const.CHANNELTALK_ICON} layout="fixed" width="70px" height="70px" className="rounded-full" />
            <span className="absolute bottom-[-22px] text-sm left-0 right-0 mx-auto font-bold">1:1 문의</span>
          </div>

          <div className="대충스크롤바 snap-mandatory overflow-x-scroll overflow-y-hidden whitespace-nowrap inline-block scrollbarhidden ml-8" onScroll={on대충스크롤바Scroll}>
            {PageStore.page.showQrcodeButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doQrcode()}>
                <Image src={Const[`CUSTOM_BUTTON_QRCODE`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showShareButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doShare()}>
                <Image src={Const[`CUSTOM_BUTTON_SHARE`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showPhoneCallButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doCall(PageStore.page!.phone)}>
                <Image src={Const[`CUSTOM_BUTTON_PHONE_CALL`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showPhoneSMSButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doSMS(PageStore.page!.phone)}>
                <Image src={Const[`CUSTOM_BUTTON_SEND_MESSAGE`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doCopyLink(PageStore.page!.anotherLink)}>
                <Image src={Const[`CUSTOM_BUTTON_COPY`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showYoutubeLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.youtubeLink)}>
                <Image src={Const[`CUSTOM_BUTTON_YOUTUBE_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showInstagramLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.instagramLink)}>
                <Image src={Const[`CUSTOM_BUTTON_INSTAGRAM_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showFacebookLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.facebookLink)}>
                <Image src={Const[`CUSTOM_BUTTON_FACEBOOK_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showTwitterLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.twitterLink)}>
                <Image src={Const[`CUSTOM_BUTTON_TWITTER_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showKakaoChannelLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.kakaoChannelLink)}>
                <Image src={Const[`CUSTOM_BUTTON_KAKAOSTORY_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {PageStore.page.showBaeminLinkButton && 
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doOpenLink(PageStore.page!.baeminLink)}>
                <Image src={Const[`CUSTOM_BUTTON_BAEMIN_LINK`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            }
            {/* {PageStore.page.customButtons.map((customButton) => (
              <div className="inline-block snap-start leading-[0] mx-[6px]" onClick={() => doCustomButton(customButton.eventType, customButton.eventArgs)} key={customButton.id}>
                <Image src={Const[`CUSTOM_BUTTON_${customButton.eventType}`]} layout="fixed" width="67px" height="90px" objectFit="contain" />
              </div>
            ))} */}
          </div>
        </div>

        <Typography variant="h6" className="mt-10 mx-[20px] font-black italic text-[#333]">NOTICE</Typography>

        <MainSwiper spaceBetween={20} slidesPerView={1.28} centeredSlides>
          {PageStore.page.notices.map((notice, idx) => (
            <SwiperSlide onClick={() => setActiveNotice(notice)} key={idx} className="relative leading-0 h-[120px] mb-4">
              <div className={`absolute left-0 right-0 top-0 bottom-0 px-4 py-2 flex flex-col justify-between w-full border border-[#d9d9d9] shadow-md rounded-xl`}>
                <Typography variant="caption" className="text-inherit">{new Date(notice.createdAt!).toLocaleDateString('ko')}</Typography>
                <Typography variant="subtitle2" className="text-inherit" noWrap>{notice.title}</Typography>
                <Typography variant="caption" className="text-inherit break-words line-clamp-2">{notice.detail.replace(/<[^]*?>/g, '')}</Typography>
                <Typography variant="caption" className={`${(idx + 1) % 2 === 0 ? 'text-[#878787]' : 'text-[#bfbfbf]'} self-end`}>더보기</Typography>
              </div>
            </SwiperSlide>
          ))}
        </MainSwiper>
      </Container>

      <Sidebar visible={!!activeNotice} position="bottom" onHide={() => setActiveNotice(null)} className="h-[84%]">
        {activeNotice && (
          <>
          <h4 className="text-sm text-[#898989]">{new Date(activeNotice.createdAt).toLocaleDateString('ko')}</h4>

          <h4 className="text-2xl font-bold mb-3">{activeNotice.title}</h4>

          <div className="w-full break-words notice-detail-container" dangerouslySetInnerHTML={{__html: activeNotice.detail}} />
          </>
        )}
      </Sidebar>
      </>
    )}
    </>
  );
}

export default observer(두번째페이지);