const S3_URL = process.env.NEXT_PUBLIC_S3_URL;
const NO_IMAGE = `${S3_URL}/assets/no_image.webp` as const;

const Const = {
  S3_URL,
  NO_IMAGE,
  MAX_TIMESTAMP: '2222-02-22T22:22:22.222222',
  MIN_TIMESTAMP: '1970-01-01T00:00:00.000000',
  LOGO_VECTOR: `${S3_URL}/assets/logo.svg`,
  HEART_IMAGE: `${S3_URL}/assets/heart.webp`,
  HEART_FULL_IMAGE: `${S3_URL}/assets/heart_full.webp`,
  BOTTOM_BUTTON_ABSOLUTE_IMAGE: `${S3_URL}/assets/bottom_button_absolute.webp`,
  FACEBOOK_IMAGE: `${S3_URL}/assets/facebook.webp`,
  INSTAGRAM_IMAGE: `${S3_URL}/assets/instagram.webp`,
  TWITTER_IMAGE: `${S3_URL}/assets/twitter.webp`,
  NAVER_BLOG_IMAGE: `${S3_URL}/assets/naver_blog.webp`,
  NAVER_CAFE_IMAGE: `${S3_URL}/assets/naver_cafe.webp`,
  NAVER_SMARTSTORE_IMAGE: `${S3_URL}/assets/naver_smartstore.webp`,
  더불어민주당_IMAGE: `${S3_URL}/assets/더불어민주당.webp`,
  국민의힘_IMAGE: `${S3_URL}/assets/국민의힘.webp`,
  정의당_IMAGE: `${S3_URL}/assets/정의당.webp`,
  무소속_IMAGE: `${S3_URL}/assets/정의당.webp`,
  kakao_IMAGE: `${S3_URL}/assets/kakao.webp`,
  band_IMAGE: `${S3_URL}/assets/band.webp`,
  link_IMAGE: `${S3_URL}/assets/link.webp`,
  CARD_NEWS_FIRST_IMAGE_COVER_IMAGE: `${S3_URL}/assets/card_news_first_image_cover.webp`,
  BOTTOM_IMAGE: `${S3_URL}/assets/bottom_image.webp`,
  더불어민주당MIC_IMAGE: `${S3_URL}/assets/더불어민주당mic.webp`,
  국민의힘MIC_IMAGE: `${S3_URL}/assets/국민의힘mic.webp`,
  무소속MIC_IMAGE: `${S3_URL}/assets/무소속mic.webp`,
  교육감MIC_IMAGE: `${S3_URL}/assets/교육감mic.webp`,
  정의당MIC_IMAGE: `${S3_URL}/assets/정의당mic.webp`,
  IOS_SAFARI_ALERT_1: `${S3_URL}/assets/ios_safari_alert.webp`,
  IOS_SAFARI_ALERT_2: `${S3_URL}/assets/ios_safari_alert_2.webp`,
  IOS_SAFARI_ALERT_3: `${S3_URL}/assets/ios_safari_alert_3.webp`,
  SURVEY_1_IMAGE: `${S3_URL}/assets/survey_1.webp`,
  SURVEY_2_VIDEO: `${S3_URL}/assets/survey_2.mp4`,
  SURVEY_3_IMAGE: `${S3_URL}/assets/survey_3.webp`,
  SURVEY_4_IMAGE: `${S3_URL}/assets/survey_4.webp`,
  SURVEY_5_IMAGE: `${S3_URL}/assets/survey_5.webp`,
  SURVEY_6_IMAGE: `${S3_URL}/assets/survey_6.webp`,
  SURVEY_7_IMAGE: `${S3_URL}/assets/survey_7.webp`,
  SURVEY_8_IMAGE: `${S3_URL}/assets/survey_8.webp`,
  SURVEY_9_IMAGE: `${S3_URL}/assets/survey_9.webp`,
  SURVEY_FIRST_IMAGE: `${S3_URL}/assets/survey_first.webp`,
  SURVEY_1_1_IMAGE: `${S3_URL}/assets/survey_1_1.webp`,
  SURVEY_1_2_IMAGE: `${S3_URL}/assets/survey_1_2.webp`,
  SURVEY_1_3_IMAGE: `${S3_URL}/assets/survey_1_3.webp`,
  SURVEY_1_4_IMAGE: `${S3_URL}/assets/survey_1_4.webp`,
  SURVEY_1_5_IMAGE: `${S3_URL}/assets/survey_1_5.webp`,
  SURVEY_1_6_IMAGE: `${S3_URL}/assets/survey_1_6.webp`,
  SURVEY_1_7_IMAGE: `${S3_URL}/assets/survey_1_7.webp`,
  SURVEY_1_8_IMAGE: `${S3_URL}/assets/survey_1_8.webp`,
  SURVEY_1_9_IMAGE: `${S3_URL}/assets/survey_1_9.webp`,
  SURVEY_1_10_IMAGE: `${S3_URL}/assets/survey_1_10.webp`,
  SURVEY_1_11_IMAGE: `${S3_URL}/assets/survey_1_11.webp`,
  SURVEY_1_12_IMAGE: `${S3_URL}/assets/survey_1_12.webp`,
  SURVEY_1_13_IMAGE: `${S3_URL}/assets/survey_1_13.webp`,
  SURVEY_1_14_IMAGE: `${S3_URL}/assets/survey_1_14.webp`,
  SURVEY_3_1_IMAGE: `${S3_URL}/assets/survey_3_1.webp`,
  SURVEY_99_1_IMAGE: `${S3_URL}/assets/survey_99_1.webp`,
  SURVEY_99_2_IMAGE: `${S3_URL}/assets/survey_99_2.webp`,
  SURVEY_99_3_IMAGE: `${S3_URL}/assets/survey_99_3.webp`,
  SURVEY_99_4_IMAGE: `${S3_URL}/assets/survey_99_4.webp`,
  SURVEY_4_1_IMAGE: `${S3_URL}/assets/survey_4_1.webp`,
  CUSTOM_BUTTON_NONE: ``,
  CUSTOM_BUTTON_YOUTUBE_LINK: `${S3_URL}/assets/custom_button_youtube_link.webp`,
  CUSTOM_BUTTON_INSTAGRAM_LINK: `${S3_URL}/assets/custom_button_instagram_link.webp`,
  CUSTOM_BUTTON_HOMEPAGE_LINK: `${S3_URL}/assets/custom_button_homepage_link.webp`,
  CUSTOM_BUTTON_FACEBOOK_LINK: `${S3_URL}/assets/custom_button_facebook_link.webp`,
  CUSTOM_BUTTON_KAKAOSTORY_LINK: `${S3_URL}/assets/custom_button_kakaostory_link.webp`,
  CUSTOM_BUTTON_TWITTER_LINK: `${S3_URL}/assets/custom_button_twitter_link.webp`,
  CUSTOM_BUTTON_BAEMIN_LINK: `${S3_URL}/assets/custom_button_baemin_link.webp`,
  CUSTOM_BUTTON_BLOG_LINK: `${S3_URL}/assets/custom_button_blog_link.webp`,
  CUSTOM_BUTTON_MINJOO_LINK: `${S3_URL}/assets/custom_button_minjoo_link.webp`,
  CUSTOM_BUTTON_GOOKHIM_LINK: `${S3_URL}/assets/custom_button_gookhim_link.webp`,
  CUSTOM_BUTTON_JUNG_LINK: `${S3_URL}/assets/custom_button_jung_link.webp`,
  CUSTOM_BUTTON_SHARE: `${S3_URL}/assets/custom_button_share.webp`,
  CUSTOM_BUTTON_SEND_MESSAGE: `${S3_URL}/assets/custom_button_send_message.webp`,
  CUSTOM_BUTTON_PHONE_CALL: `${S3_URL}/assets/custom_button_phone_call.webp`,
  CUSTOM_BUTTON_OPEN_IMAGE: `${S3_URL}/assets/custom_button_open_image.webp`,
  CUSTOM_BUTTON_COPY: `${S3_URL}/assets/custom_button_copy.webp`,
  CUSTOM_BUTTON_HOMESCREEN: `${S3_URL}/assets/custom_button_homescreen.webp`,
  CUSTOM_BUTTON_QRCODE: `${S3_URL}/assets/custom_button_qrcode.webp`,
  CUSTOM_BUTTON_BANKOPEN: `${S3_URL}/assets/custom_button_bankopen.webp`,
  CUSTOM_BUTTON_JUNGCHAEK: `${S3_URL}/assets/custom_button_jungchaek.webp`,
  CUSTOM_BUTTON_BUY_LINK: `${S3_URL}/assets/custom_button_jungchaek.webp`,
  CHANNELTALK_ICON: `${S3_URL}/assets/channeltalk_icon.webp`,
  NOTEPAD_1: `${S3_URL}/assets/notepad_1.webp`,
  NOTEPAD_2: `${S3_URL}/assets/notepad_2.webp`
} as const;

export default Const;
